body {
  margin: 0;
  font-family: 'Helvetica', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* CSS Reset */
* {
  padding: 0;
  box-sizing: border-box;
  /* transition: all 0.25s ease; */
}

.sr-only {
  display: none;
}

.hidden {
  visibility: hidden;
}

a {
  color: inherit;
  text-decoration: none;
  border-bottom-color: currentColor;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  box-sizing: content-box !important;
}

.disabled {
  position: relative;
  opacity: 0.5;
}
.disabled::before {
 content: ' ';
 position: absolute;
 width: 100%;
 height: 100%;
 z-index: 3;
 background: transparent;
}

/* debug */
/* * {
  background: #FF00F011;
} */
